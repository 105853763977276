import { ChangeDetectionStrategy, Component, output, input, computed } from '@angular/core';

type ChipSize = 'small' | 'default';
type ChipColor = 'default' | 'success' | 'warning' | 'error';

@Component({
  selector: 'cc-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class ChipComponent {
  readonly size = input<ChipSize>('default');
  readonly color = input<ChipColor>('default');
  readonly leadingIcon = input<string | undefined>(undefined);

  readonly deletable = input(false);

  delete = output<void>();

  protected readonly chipClasses = computed(() => {
    return [`chip--size-${this.size()}`, `chip--color-${this.color()}`].join(' ');
  });
}
