<div
  class="chip"
  [class]="chipClasses()"
>
  @if (leadingIcon()) {
    <i
      class="chip__leading-icon"
      [class]="leadingIcon()"
    ></i>
  }

  <div class="chip__content">
    <ng-content></ng-content>
  </div>

  @if (deletable()) {
    <i
      (click)="delete.emit()"
      class="chip__delete-icon icon-clear"
    ></i>
  }
</div>
